const sendMailUrl = 'https://legalai.kz/api/auth/notice/saveEmail'
const emailInput = document.getElementById('emailInputDev')
const sendButton = document.getElementById('sendButtonDev')
const inputBlock = document.getElementById('inputBlockDev')

if (emailInput && sendButton && inputBlock) {
  let isTouched = false

  if (emailInput.value.trim().length === 0) {
    sendButton.setAttribute('disabled', 'disabled');
  }

  emailInput.addEventListener('blur', () => {
    isTouched = true
    validateEmail()
  })

  emailInput.addEventListener('input', () => {
    validateEmail()
  })


  function validateEmail() {
    const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailInput.value.trim().length !== 0 && regExp.test(emailInput.value)) {
      sendButton.removeAttribute('disabled');
      inputBlock.classList.remove('error')
    } else {
      sendButton.setAttribute('disabled', 'disabled');
      if (isTouched) {
        inputBlock.classList.add('error')
      }
    }
  }

  sendButton.addEventListener('click', () => {
    const url = `${sendMailUrl}/${emailInput.value}`
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then(response => response.json())
      .then(() => emailInput.value = '')
  })
}