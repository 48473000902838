document.addEventListener("DOMContentLoaded", function () {
  const switchBoardItems = document.querySelectorAll('.switch-board__item');
  const btnUp = document.querySelector('.switch-board__btn-up');
  const btnDown = document.querySelector('.switch-board__btn-down');
  const isMobile = window.matchMedia("(max-width: 480px)").matches;

  if (switchBoardItems && btnUp && btnDown) {
    let currentIndex = 0;
    let isSliding = false;
    const translate = isMobile ? 'translateX' : 'translateY';

    function updateSwitchBoard(direction) {
      if (isSliding) return;
      isSliding = true;

      const currentItem = switchBoardItems[currentIndex];
      const nextIndex = direction === 'down' ? currentIndex + 1 : currentIndex - 1;

      if (nextIndex < 0 || nextIndex >= switchBoardItems.length) {
        isSliding = false;
        return;
      }

      const nextItem = switchBoardItems[nextIndex];

      currentItem.style.transform = direction === 'down' ? `${translate}(-100%)` : `${translate}(100%)`;
      currentItem.style.opacity = '0';

      setTimeout(() => {
        currentItem.style.display = 'none';

        nextItem.style.display = 'flex';
        nextItem.style.transform = direction === 'down' ? `${translate}(100%)` : `${translate}(-100%)`;

        setTimeout(() => {
          nextItem.style.opacity = '1';
          nextItem.style.transform = `${translate}(0)`;
        }, 10);

        currentIndex = nextIndex;

        btnUp.style.width = currentIndex === 0 ? '0' : '48px';
        btnDown.style.width = currentIndex === switchBoardItems.length - 1 ? '0' : '48px';

        isSliding = false;
      }, 500);
    }

    btnUp.addEventListener('click', function () {
      if (currentIndex > 0) {
        updateSwitchBoard('up');
      }
    });

    btnDown.addEventListener('click', function () {
      if (currentIndex < switchBoardItems.length - 1) {
        updateSwitchBoard('down');
      }
    });

    const initialItem = switchBoardItems[currentIndex];
    initialItem.style.display = 'flex';
    setTimeout(() => {
      initialItem.style.opacity = '1';
      initialItem.style.transform = `${translate}(0)`;
    }, 10);

    btnUp.style.width = '0';
    btnDown.style.width = currentIndex === switchBoardItems.length - 1 ? '0' : '48px';

    // setInterval(function () {
    //   if (currentIndex < switchBoardItems.length - 1) {
    //     updateSwitchBoard('down');
    //   } else {
    //     updateSwitchBoard('up');
    //   }
    // }, 5000);
  }
});