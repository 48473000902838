const langToggle = document.querySelector('.lang-toggle');

if (langToggle) {
  const newLang = localStorage.getItem('lang') === 'kz' ? 'ru' : 'kz';
  langToggle.innerText = newLang === 'ru' ? 'Рус' : 'Қаз';

  langToggle.addEventListener('click', function (e) {
    e.preventDefault();
    localStorage.setItem('lang', newLang);
    location.reload();
  });
}