const faqQuestions = document.querySelectorAll('.faq__item-question');

if (faqQuestions) {
  faqQuestions.forEach(question => {
    question.addEventListener('click', () => {
      const faqItem = question.parentElement;
      const answer = faqItem.querySelector('.faq__item-answer');
      const isActive = faqItem.classList.contains('active');

      if (isActive) {
        faqItem.classList.remove('active');
        answer.style.maxHeight = '0';
        answer.style.opacity = '0';
        answer.style.paddingTop = '0';
      } else {
        document.querySelectorAll('.faq__item').forEach(item => {
          if (item !== faqItem) {
            item.classList.remove('active');
            item.querySelector('.faq__item-answer').style.maxHeight = '0';
            item.querySelector('.faq__item-answer').style.opacity = '0';
            item.querySelector('.faq__item-answer').style.paddingTop = '0';
            item.querySelector('.faq__item-answer').style.paddingBottom = '0';
          }
        });

        faqItem.classList.add('active');
        answer.style.maxHeight = answer.scrollHeight + 'px';
        answer.style.opacity = '1';
        answer.style.paddingTop = '24px';
      }
    });
  });
}