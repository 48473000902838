document.addEventListener("DOMContentLoaded", function () {
  const hamburgerMenu = document.querySelector('.header__right-menu');
  const mobileMenu = document.querySelector('.mobile-menu');

  if (hamburgerMenu && mobileMenu) {
    hamburgerMenu.addEventListener('click', function () {
      hamburgerMenu.classList.toggle('active');
      if (mobileMenu.classList.contains('active')) {
        mobileMenu.classList.remove('active');
        setTimeout(() => {
          mobileMenu.style.display = 'none';
        }, 500);
      } else {
        mobileMenu.style.display = 'flex';
        setTimeout(() => {
          mobileMenu.classList.add('active');
        }, 10);
      }
    });

    window.addEventListener('scroll', function () {
      if (mobileMenu.classList.contains('active')) {
        hamburgerMenu.classList.toggle('active');
        mobileMenu.classList.remove('active');
        setTimeout(() => {
          mobileMenu.style.display = 'none';
        }, 500);
      }
    });
  }
});