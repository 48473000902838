const contactForm = document.querySelector('form');

if (contactForm) {
  const phoneInput = document.getElementById('phoneInput');
  const sendButton = document.getElementById('sendButton')
  const inputBlock = document.getElementById('phoneInputBlock')
  console.log(phoneInput)

  let isTouched = false

  if (phoneInput.value.trim().length === 0) {
    sendButton.setAttribute('disabled', 'disabled');
  }

  phoneInput.addEventListener('blur', () => {
    isTouched = true
    validatePhone()
  })

  phoneInput.addEventListener('input', () => {
    validatePhone()
  })

  function validatePhone() {
    const phonePattern = /^\+7\s?\(?[0-9]{3}\)?\s?[0-9]{3}\s?[0-9]{2}\s?[0-9]{2}$/;

    if (phonePattern.test(phoneInput.value)) {
      sendButton.removeAttribute('disabled');
      inputBlock.classList.remove('error')
    } else {
      sendButton.setAttribute('disabled', 'disabled');
      if (isTouched) {
        inputBlock.classList.add('error')
      }
    }
  }
}