import './accordion.js';
import './carousel.js';
import './contact-form.js';
import './header-navbar.js';
import './header-scroll.js';
import './lang-switcher.js';
import './header-mobile.js'
import './switch-board.js'
import './contact-mail.js'


const docs = document.querySelectorAll('.footer__right-doc');
const modalBg = document.querySelector('.modal-bg')
const modal = document.querySelector('.modal')
const modalContent = document.querySelector('.modal__body-content')
const closeBtn = document.querySelector('.modal__close-btn')

docs.forEach(item => {
  item.addEventListener('click', () => {
    modalContent.innerHTML = item.getAttribute('data-content');
    modalBg.classList.add('active');
    modal.classList.add('active');
  })
})

closeBtn.addEventListener('click', () => {
  closeModal()
})

modalBg.addEventListener('click', () => {
  closeModal()
})

function closeModal() {
  modalBg.classList.remove('active');
  modal.classList.remove('active');
}