const reviewsContainer = document.querySelector('.reviews__container');
const carouselContainer = document.querySelector('.carousel');
const prev = document.querySelector('.carousel-controls__prev');
const next = document.querySelector('.carousel-controls__next');
const carouselItems = document.querySelectorAll('.carousel__item');

if (reviewsContainer && carouselContainer && prev && next && carouselItems.length > 0) {
  let counter = 0;
  let infiniteCounter = 0;
  let itemWidth = carouselItems[0].offsetWidth + 20;
  let startX = 0;
  let endX = 0;

  const updateItemWidth = () => {
    itemWidth = carouselItems[0].offsetWidth + 20;
  };

  window.addEventListener('resize', updateItemWidth);

  reviewsContainer.addEventListener('click', function (e) {
    carouselContainer.style.transition = '.5s ease all';
    if (e.target.classList.contains('carousel-controls__prev')) {
      infiniteCounter = -1;
      counter += itemWidth;
      carouselContainer.style.transform = `translateX(${counter}px)`;
    }

    if (e.target.classList.contains('carousel-controls__next')) {
      infiniteCounter = 1;
      counter -= itemWidth;
      carouselContainer.style.transform = `translateX(${counter}px)`;
    }
  });

  let infiniteCarousel = function () {
    carouselContainer.style.transition = 'none';
    counter = 0;

    if (infiniteCounter === 1) {
      carouselContainer.appendChild(carouselContainer.firstElementChild);
      carouselContainer.style.transform = `translateX(0px)`;
    }

    if (infiniteCounter === -1) {
      carouselContainer.style.transform = `translateX(0px)`;
      carouselContainer.prepend(carouselContainer.lastElementChild);
    }
  };

  carouselContainer.addEventListener('transitionend', infiniteCarousel);

  carouselContainer.addEventListener('touchstart', (e) => {
    startX = e.touches[0].clientX;
  });

  carouselContainer.addEventListener('touchmove', (e) => {
    endX = e.touches[0].clientX;
  });

  carouselContainer.addEventListener('touchend', () => {
    const swipeDistance = startX - endX;
    if (swipeDistance > itemWidth / 3) { // Swipe left
      infiniteCounter = 1;
      counter -= itemWidth;
      carouselContainer.style.transition = '.5s ease all';
      carouselContainer.style.transform = `translateX(${counter}px)`;
    } else if (swipeDistance < -itemWidth / 3) { // Swipe right
      infiniteCounter = -1;
      counter += itemWidth;
      carouselContainer.style.transition = '.5s ease all';
      carouselContainer.style.transform = `translateX(${counter}px)`;
    }
  });
}