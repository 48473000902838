document.addEventListener("DOMContentLoaded", function () {
  const currentPath = window.location.pathname.replace(/\.html$/, '');
  const navLinks = document.querySelectorAll('.header__left-nav ul li a');
  const mobileNavLinks = document.querySelectorAll('.mobile-nav ul li a');

  const makeHighlightActive = (link) => {
    if (link) {
      const linkPath = link.getAttribute('href').replace(/\.html$/, '');
      if (linkPath === currentPath) {
        link.classList.add('active');
      }
    }
  }

  navLinks.forEach(link => makeHighlightActive(link));
  mobileNavLinks.forEach(link => makeHighlightActive(link));
});